import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEn from './i18n/translation.en.json'
import translationKo from './i18n/translation.ko.json'

export const Korean = "ko-KR";
export const English = "en-US";

const resource = {
  'en-US' : {
    translation : translationEn
  },
  'ko-KR' : {
    translation : translationKo
  }
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: resource,
    returnObjects : true,
    lng : Korean,
    fallbackLng: English,
    debug : false,
    keySeparator: ".",    // key 연결 접근 표시 여부
    nsSeparator: false,   // namespace 표시여부, false는 무시, '.'은 translation.key 처럼
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;